/* .w-100vw {
    width: 100vw;
  }
   */
  /* .px-5 {
   width: 100%;
  } */
  
  .drop-btn{
    border-radius: 7px;
    border: none;
    }
    
    #icon{
    text-decoration: none;
    color: orangered;
    }
    
    #login-submit{
    background: orangered;
    }
    
    .btn:focus {
    outline: none;
    box-shadow: none;
    border: none;
    }
    
    /* #bttn2{
       float: right;
       margin-right: 2%;
       color: white;
    } */
    
    #icon1{
      color: white;
      /* padding: 6px; */
      margin-left: 8px;
    }
    
    #icon2{
      color: white;
      margin-right: 40px;
      /*  margin-right: 30px; */
    }
    .buttonUpload{
      background-color: orangered;
    }
    .buttonUpload:hover{
      background-color: orangered;
    }
    
    /* #hr {
      margin-top: 1rem;
      margin-bottom: 1rem;
      border: 0;
      border-top: 10px solid rgba(248, 203, 20, 0.916);
    } */
    .Original_ver {
      margin-top: 0px;
      /* margin-bottom: 5px; */
      width: auto !important;
      height: auto !important;
      border: 1px solid rgb(224, 30, 30);
      background: #eff4f7 !important;
      /* padding:% */
  }
  .saveButton1 {
    font-weight: 500;
    width: 45%;
    background-color: teal;
    margin-left: 1%;
  }
  .backButton1 {
    font-weight: 500;
    width: 45%;
  }
  .original_pdf_block,
  .buttons_block_ver,
  .extracted_data_block {
      min-height: 87vh;
  }
  .scheduleStyle{
    background-color:white;
    font-weight: 500;
    padding:5px;
    min-height:8vh;
    height:auto;
    border: 1px solid rgba(0,0,0,0.3);
    margin-top:5px;
    /* cursor: pointer; */
    transition: background-color 0.2s;
    box-shadow: 0px 1px 5px rgba(149, 157, 165, 0.5);
    transition: all 0.4s;
    border-left: 5px solid teal;
    border-radius: 0;
  }
  .scheduleStyle:hover{
    transform: scale(1.1) ;
  }
  .centered{
    margin-top:10%;
    text-align: center;
  }
  .tableCardstyle1 {
    width: 98%;
    margin-left: 2%;
    margin-top: 1%;
    margin-bottom: 3%;
    height: 83vh;
    transition: background-color 0.2s;
    box-shadow: 0px 1px 5px rgba(149, 157, 165, 0.5);
    border: 1px solid #ccc;
  }
  .heading19 {
    background-color: white;
  }
  .heading20 {
    padding: 0;
    margin:0;
    /* margin-left: 2%; */
    margin-top: 1%;
    border-radius: 0;
    font-weight: 500;
    padding-left: 2%;
    border: 1px solid  red;
  }
  
  .heading21 {
    padding: 0;
    margin:0;
    /* margin-left: 2%; */
    margin-top: 1%;
    border-radius: 0;
    font-weight: 500;
    padding-left: 2%;
    border: 1px solid  green;
  }
  .heading {
    padding: 0;
    margin: 0;
  }
  
  .heading2 {
    width: 90%;
    float: left;
    font-weight: 600;
  }
  
  .heading3 {
    width: auto;
    height: 22px;
    margin-top: 2%;
    cursor: pointer;
    color: red;
    float: right;
  }
  
  .heading4 {
    width: 100%;
  }
  
  .heading5 {
    font-weight: 500;
    cursor: pointer;
    display: inline-block;
    width: 90%;
  }
  
  .heading6 {
    width: 80%;
    float: left;
    font-weight: 600;
  }
  
  .heading7 {
    padding: 0;
    margin: 0;
  }
  
  .heading8 {
    width: auto;
    height: 22px;
    margin-top: 2%;
    cursor: pointer;
    color: red;
    float: right;
  }
  
  .heading9 {
    width: 100%;
  }
  
  .heading10 {
    font-weight: 500;
    cursor: pointer;
    display: inline-block;
    width: 90%;
  }
  
  .heading11 {
    margin: 0px;
  }
  
  .heading12 {
    background-color: white;
  }
  
  .heading13 {
    background-color: white;
    border: 1px solid darkgrey;
    text-align: center;
    font-weight: 600;
    border-radius: 3px;
    margin-left: 30%;
    margin-right: 30%;
    margin-top: 1%;
  }
  
  .heading14 {
    padding-top: 10%;
    background-color: white;
  }
  .errorMsgNP{
    font-weight: 500;
    border: 1px solid red;
  }
  
  #heading15,#heading16,#heading17,#heading18 {
    background-color: teal;
    border: none;
    font-weight: 500;
  }
  
  .heading19 {
    background-color: white;
  }
  
  .heading20 {
    padding: 0;
    margin:0;
    /* margin-left: 2%; */
    margin-top: 1%;
    border-radius: 0;
    font-weight: 500;
    padding-left: 2%;
    border: 1px solid  red;
  }
  
  .heading21 {
    padding: 0;
    margin:0;
    /* margin-left: 2%; */
    margin-top: 1%;
    border-radius: 0;
    font-weight: 500;
    padding-left: 2%;
    border: 1px solid  green;
  }
  
  .heading22 {
    display: flex;
    /* margin-bottom:5%; */
    margin-top:2%;
    
  }
  
  .heading23 {
    margin-right: 5%;
  }
  
  .scrollView {
    scroll-behavior: smooth;
    overflow-y: scroll;
    height:92vh;
    margin: 0;
    background-color: rgba(0,0,0,0.1);
  }
  .scheduleStyle{
    background-color:white;
    font-weight: 500;
    padding:5px;
    min-height:8vh;
    height:auto;
    border: 1px solid rgba(0,0,0,0.3);
    margin-top:5px;
    /* cursor: pointer; */
    transition: background-color 0.2s;
    box-shadow: 0px 1px 5px rgba(149, 157, 165, 0.5);
    transition: all 0.4s;
    border-left: 5px solid teal;
    border-radius: 0;
  }
  .scheduleStyle:hover{
    transform: scale(1.1) ;
  }
  
  .boxIsDesc{
    height:12px;
    width:12px;
    background-color:red;
  }
  
  .centered{
    margin-top:20%;
  }
  /* .original_pdf_block,
  .buttons_block_ver,
  .extracted_data_block {
      min-height: 93vh;
  } */
  .customize{
      margin-right: 12%;
  }
  
  /* .shcedule_index_ver {
      margin-top: 5%;
      margin-left: 10%;
      width: 40%;
      height: 100px;
      margin-bottom: 0px;
      border:1px solid gray;
  } */
  
  .originalPdfBlock {
      border: 1px solid gray;
      margin-top: 2%;
      border-radius: 0;
      padding: 2px;
      flex-direction: row;
  }
  
  .spanSchedule {
      font-weight: 600;
      font-size: 16px;
      color: #006699;
  }
  
  .schedulePart {
      /* margin-right: 10%; */
      margin-left: 5%;
  }
  
  .verticleLine {
      display: inline-block;
      border-right: 1px solid #ccc;
      height: auto;
  }
  
  .Original_ver {
      margin-top: 0px;
      margin-bottom: 5px;
      width: auto !important;
      height: auto !important;
      border: 1px solid gray;
      background: #eff4f7 !important;
      /* padding:% */
  }
  
  .vl_verifiaction {
      border-left: 1px solid gray;
      height: 99px;
      position: absolute;
      left: 13%;
      /* top: 0; */
  }
  
  .center_buttons_ver {
      width: 100%;
      margin-right: 0px;
      margin-top: 10%;
  }
  
  
  .select_Val {
      width: 40%;
      height: 35px;
  }
  
  .box {
      margin: 0px;
      padding-bottom: 10px;
  }
  
  .box select {
      background-color: white;
      color: black;
      padding: 8px;
      width: 250px;
      border: 1px solid #DADBDC;
      font-size: 16px;
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
      -webkit-appearance: button;
      appearance: button;
      outline: none;
      border-radius: 5px;
  }
  
  .box:hover::before {
      color: rgba(255, 255, 255, 0.6);
      background-color: rgba(255, 255, 255, 0.2);
  }
  
  .box select option {
      margin: 100px;
  }
  .react-pdf__Page__canvas {
      margin: 0 auto;
      width: 95% !important;
      height: 85vh !important;
      border: 1px solid darkgrey;
      box-shadow: 5px 0px 5px 1px #ccc;
      border-radius: 5px;
      margin-top: 2%;
  }
  .all-page-container {
      height: 100%;
      max-height: 500px;
      overflow: auto;
  }
  /* .react-pdf__Page__textContent {
      border: 1px solid darkgrey;
      box-shadow: 5px 5px 5px 1px #ccc;
      border-radius: 5px;
  } */
  
  
  .tableCardstyle1 {
      width: 98%;
      margin-left: 2%;
      margin-top: 1%;
      margin-bottom: 3%;
      height: 83vh;
      transition: background-color 0.2s;
      box-shadow: 0px 1px 5px rgba(149, 157, 165, 0.5);
      border: 1px solid #ccc;
  }
  
  #alertStyleInProject1 {
      font-weight: 500;
      height: 25px;
      margin: 0;
      padding: 0;
      font-size: 14px;
      border-radius: 0;
      padding-left: 10px;
      text-align: left;
      padding-top: 0x;
      color: #17202A;
      border: 1px solid #ccc;
      /* border-top-left-radius: 3px;
      border-top-right-radius: 3px; */
      background-color: white ;
  }
  
  .saveButton1 {
      font-weight: 500;
      width: 45%;
      background-color: teal;
      margin-left: 2%;
  }
  
  .backButton1 {
      font-weight: 500;
      width: 45%;
  }
.ant-table-tbody>tr.ant-table-row:hover>td {
  background: none !important;
}
.ant-table-tbody{
 background:#eaeaea;
}
.ant-table-tbody > tr > td{
  border: #eaeaea;
}

/* save button color    rgb(246,131,36) */


#bookmarking{
  font-family: 'Poppins', sans-serif;
}

#setected_schedul{
  font-family: 'Poppins', sans-serif;
 /* font-weight: bold; */
}

#pdftitle{
  font-family: 'Poppins', sans-serif;
  font-weight: bold;
}

#varification_Table{
  font-family: 'Poppins', sans-serif;
  font-weight: regular;
  font-size: 15px;
  color: #000000;

}


.setwidth{
  max-width: 100px;
  /* overflow-x: scroll; */
}
.concat div{
  overflow: hidden;
  -ms-text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: inherit;
}