.w-100vw {
  width: 100vw;
}

.h-100vh {
  height: 100vh;
}

.mnh-50vh {
  min-height: 50vh;
}

#loginLayout-img-Demo {
  background-image: url("../../Images/loginLayout.jpg");
  background-size: cover;
  color: rgba(0, 0, 0, 0.7);

}

#login-submit {
  background-color: #F6770F;
}

.logo-Demo {
  background-image: url("../../Images/Logo.png");
  background-size: 100%;
  height: 16vh;
  width: 21vh;
}

.video {
  aspect-ratio: 16/9;
  height: 25%;
  border-radius: 10px;
}

.sub-head {
  padding-left: 2em
}

.form-check {
  padding-left: 2.5em;
}

.px-5 {
  padding-right: 7rem !important;
  padding-left: 7rem !important;
}

.my-4 {
  margin-left: -7%;
}

#anchor {
  /* color: #F6770F; */
  color: #F68324;
  text-decoration: none
}

.sub-head {
  padding-left: 4em
}

#p1_Demo {
  width: 68%;
  padding-left: 6%;
  font-family: 'poppins', sans-serif;
  color:rgb(230, 230, 230);
  width: 92% !important;
  margin-left:20px;
  /* background-color:rgb(2, 43, 83) ; */
}

#p2_Demo {
  font-family: 'poppins', sans-serif;
  color:rgb(230, 230, 230);
  width: 87.5% !important;
  margin-left:54px;
}


#vidplayer {
  width: 85% !important;

}



/* comment this if  you are using it in RequestDemo */
#alertInDemo {
  /* margin-left: 19%; */
  width: 45%;
}

.col-10 {
  flex: 0 0 auto;
  width: 100%;
}

.nu {
  color: rgb(51, 51, 51);
  font-family: 'Poppins', sans-serif;
    /* font-weight: bold; */
}

#helloo{
  font-family: 'Poppins', sans-serif;
  font-weight: bold;
  margin-top: 50px;
  padding-right: 10%;
}

#formfamily1{
  font-family: 'Poppins', sans-serif;
    font-weight: bold;
}

#backto{
  font-family: 'Poppins', sans-serif;
  font-weight: bold;
}

#new{
  background-color: #F6770F;
}

