.btn,.btn-lignt:hover{
    border: none !important;
}
.linkHeader{
    text-decoration: none;
    font-weight: 600;
    color:black;
}

#dropid{
    font-family: ' Poppins-Regular', sans-serif;
    font-weight: bold;
}

#dropmenuscss{
    font-family: ' Poppins-Regular', sans-serif;
    font-weight: bold;
}

#v1{
    padding-right: 15px;
    color: grey;
    border-left: 2px solid rgb(185, 182, 182);
}