
.w-100vw {
    width: 100vw;
  }
  
  .px-5 {
   width: 100%;
}
  
 .drop-btn{
  border-radius: 7px;
  border: none;
 }

 #icon{
  text-decoration: none;
  color:#F68324;
 }

 #login-submit{
  background: #F68324;
 }

 .btn:focus {
  outline: none;
  box-shadow: none;
  border: none;
  }
  


  /* Css aaplied on ---->13-10-2022 */
  .spacing{
    /* justify-content: space-between; */
    justify-content: space-around;
  }

  

  #user{
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
  }

  #userform{
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
  }

  .w-100 {
    width: 100%!important;
}

#hr2 {
    
  border-top: 10px solid sandybrown;
}

.ant-popover-buttons>.ant-btn-default{
  font-size: 15px;
  width:80px;
  height: 30px;
  margin-left: 15%;
  font-family: 'Poppins', sans-serif;
 }
 
 .ant-popover-buttons>.ant-btn-primary{
   font-size: 15px;
   width:80px;
   height: 30px;
   margin-right: 22%;
   font-family: 'Poppins', sans-serif;
  }
 
  #userpop{
   font-family: 'Poppins', sans-serif;
   font-weight: bold;
  }