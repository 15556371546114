


.w-100vw {
  width: 100vw;
}

.px-5 {
 width: 100%;
}

.drop-btn{
border-radius: 7px;
border: none;
}

#icon{
text-decoration: none;
color:#F27A18;
}

#login-submit{
background: #F27A18;
}

.btn:focus {
outline: none;
box-shadow: none;
border: none;
}

#bttn2_Export{
   float: right;
   margin-right: 2%;
   color: white;
   font-family: 'Poppins', sans-serif;
   font-size:18px;
   align-items: center;
   
 
}
#bttn2_Upload{
  float: right;
  margin-right: 2%;
  color: white;
  font-family: 'Poppins', sans-serif;
  font-size:18px;
}

#icon1{
  color: white;
  /* padding: 6px; */
  font-weight: lighter;
  margin-left: 8px;
}

#icon2{
  color: white;
  margin-right: 40px;
  
  

   /* margin-right: 30px; */
}
.buttonUpload{
  background-color:#F27A18;
}
.buttonUpload:hover{
  background-color: #F27A18;
}

#hr1 {
  
  border-top: 10px solid sandybrown;
}



.dropdown-item:hover{
     background: #000;
}

.ant-menu-horizontal, .ant-menu-item::after, .ant-menu-submenu::after {
  border: none !important;
}

.active-row{
color:#0aa3f5;
font-family: 'Poppins', sans-serif;
font-weight: bold;
}

.dropdown-item:hover{
color: white;
}

.dropdown-menu[data-bs-popper] {
top: 100%;
left: -25px;
margin-top: var(--bs-dropdown-spacer);
}



.ant-card-bordered {
/* border: 1px solid #f0f0f0; */
border: none;
}


.ant-table-column-title{
color: grey;

}




.ant-table-tbody>tr.ant-table-row:hover>td {
background: none !important;
}



#createProjectTitle{
font-family: 'Poppins', sans-serif;
  /* font-weight: bold; */
}

#projectid{
font-family: 'Poppins', sans-serif;
font-weight: bold;
font-size: 25px;
}

.table-row-dark{
font-family: 'Poppins', sans-serif;
font-weight: bold;
}

#table1{
font-family: ' Poppins', sans-serif;
font-weight: 400;
color:#141414;
font-size: 14.5px;
}

#table2{
font-family: ' Poppins', sans-serif;
font-weight: bold;
color: rgb(70, 70, 70);
/* font-size: 17px; */
}

.ant-form {
margin-top: 2px;
}

#exportbuttonclicked{
font-family: ' Poppins-Regular', sans-serif;
font-weight: bold;
}
.table-striped-rows{
background:white;

}


.ant-table-thead>tr>th {

font-weight: 700 !important;  
color: grey;

}


/* changes done on 26-10-2022  css properties related to table */

.ant-table-tbody .ant-table-cell:nth-child(even) {
background-color: white;
}

.ant-table-tbody .ant-table-cell:nth-child(odd) {
background-color: white;
}


#table1 .ant-table-tbody .ant-table-cell:nth-child(odd) {
background-color: #F8F9FA;
  height: 15px;
  padding: 14px;
  
}
#table1 .ant-table-tbody .ant-table-cell:nth-child(even) {
background-color: #F8F9FA;
/* height: 15px;
padding: 14px; */
}

#table2 .ant-table-tbody .ant-table-cell:nth-child(even){
height: 14px;
  padding: 12px;

}


.setwidthCreate{
max-width: 500px;
/* overflow-x: scroll; */
}
.concatCreate div{
overflow: hidden;
-ms-text-overflow: ellipsis;
-o-text-overflow: ellipsis;
text-overflow: ellipsis;
white-space: nowrap;
width: inherit;
}

#proN{
overflow: hidden;
-ms-text-overflow: ellipsis;
-o-text-overflow: ellipsis;
text-overflow: ellipsis;
white-space: nowrap;
width: inherit;
}

#projectpopup{
font-family: ' Poppins-Regular', sans-serif;
margin-left: 8%;
font-size: 18px;
}

#filepopup{
font-family: ' Poppins-Regular', sans-serif;
margin-left: 8%;
font-size: 18px;
}



.ant-modal-content {
margin-top: 45%;
position: relative;
background-color: #fff;
background-clip: padding-box;
border: 0;
border-radius: 14px;
box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%), 0 9px 28px 8px rgb(0 0 0 / 5%);
pointer-events: auto;
}

.ant-btn-default{
width: 120px;
border-radius: 10px;
height: 40px;
background-color: black;
color: white;
/* margin-right: 0%; */
margin-right: 1px;
font-family: ' Poppins-Regular', sans-serif; 
font-size: 18px;
}
.ant-btn-primary{
width: 120px;
border-radius: 10px;
height: 40px;
background-color: #F27A18;
color: white;
/* margin-right: 17%; */
margin-right: 105px;
font-family: ' Poppins-Regular', sans-serif; 
font-size: 18px;
}

.ant-btn-default:hover{
background-color: black;
color: white;
}

.ant-btn-primary:hover{
background-color: #F27A18;
color: white;
}

.ant-modal-footer{
border: none;
margin-top: -14px;
}