.w-100vw {
    width: 100vw;
  }
  
  .px-5 {
   width: 100%;
}
  
 .drop-btn{
  border-radius: 7px;
  border: none;
 }

 #icon{
  text-decoration: none;
  color:#F68324;
 }

 #login-submit{
  background: #F68324;
 }

 .btn:focus {
  outline: none;
  box-shadow: none;
  border: none;
  }
  


  /* Css aaplied on ---->13-10-2022 */
  .spacing{
    /* justify-content: space-between; */
    justify-content: space-around;
  }

  #firstlogin{
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
  }

  #hr3 {
    
    border-top: 10px solid #F8F9FA;
   
  }

  #hrfirst{
    border-top: 10px solid sandybrown;
  }