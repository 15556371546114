.cardInApp{
    height: 40vh;
    width: 70%;
    padding-bottom: 10px;
    border: 1px solid gray;
    background-color:#FBFCFC;
    margin: 0 auto; /* Added */
    float: none; /* Added */
    margin-bottom: 10px; /* Added */
    margin-top: 10vh;
}
#Approvebutt{
    width:50%;
    margin-left: 45%;
}
#Cancelbutt{
    width:50%;
    margin-left: 5%;
}